<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->
<template>
  <v-container fluid class="white h-100 pa-0" :class="$vuetify.breakpoint.mdAndDown ? 'bg-mobile' : ''">
    <temporary-token-request v-show="!token" :invalid-token="invalidToken" @submit="invalidToken = false" />
    <set-new-password v-show="token" @submit="submit" />
  </v-container>
</template>

<script>
  import SetNewPassword from '@/modules/auth/SetNewPassword'
  import {resetPasswordWithToken} from '@/modules/auth/api'
  import TemporaryTokenRequest from '@/modules/auth/TemporaryTokenRequest'

  export default {
    name: 'ResetPassword',
    components: {TemporaryTokenRequest, SetNewPassword},
    data() {
      return {
        token: this.$route.query.token,
        invalidToken: false
      }
    },
    methods: {
      async submit(password, done) {
        try {
          await resetPasswordWithToken(this.token, password)
          await this.$router.push({name: 'login'})
          this.success = true
        } catch (error) {
          if (error.response.status === 401) {
            this.invalidToken = true
            this.token = null
          } else {
            this.$root.$emit('error', error)
          }
        } finally {
          done()
        }
      }
    }
  }
</script>

