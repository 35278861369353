<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-row no-gutters class="fill-height" justify="center" align="center">
    <v-col cols="12" lg="6" class="d-none d-lg-flex fill-height flex-column justify-center">
      <v-img :src="backGroundImage" />
    </v-col>
    <v-col cols="12" lg="6">
      <v-form ref="form" class="mx-auto" lazy-validation @submit.prevent="submit">
        <v-card flat color="transparent" class="mx-auto" min-width="320" width="400">
          <v-col cols="12">
            <v-img :src="require('@/assets/img/maPread_logo_color.svg')" max-width="250px" width="350" class="mb-3 mx-auto" max-height="70px" contain />
            <div class="text-caption font-alt mt-8">
              <p class="mb-2">Veuillez renseigner l'adresse e-mail du compte dont vous souhaitez réinitialiser le mot de passe.</p>
              <p class="mb-0">Un e-mail contenant un lien de réinitialisation de mot de passe vous sera envoyé.</p>
            </div>
          </v-col>
          <v-col cols="12">
            <v-text-field v-model="email" :maxlength="80" label="E-mail" outlined dense hide-details="auto" type="email" :rules="[$rules.required, $rules.email]" />
          </v-col>
          <v-col v-if="invalidToken" cols="12">
            <div class="error--text text-caption">Le lien de réinitialisation de mot de passe a expiré</div>
          </v-col>
          <v-card-actions class="d-flex justify-end">
            <v-btn type="button" small :to="{name: 'login'}">Annuler</v-btn>
            <v-btn type="submit" small color="primary" :loading="loading">Valider</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-col>
    <modal v-model="success" color="color-dialog informations"
           title="E-mail envoyé"
           subtitle="S'il existe un compte avec cette adresse mail, un e-mail de réinitialisation de mot de passe vient de vous être envoyé."
           :img="require('@/assets/img/illustrations/reset-password.svg')" :actions="false"
    />
  </v-row>
</template>

<script>
  import Modal from '@/modules/core/components/Modal'
  import {temporaryTokenRequest} from '@/modules/auth/api'
  import {mapState} from 'vuex'

  export default {
    name: 'TemporaryTokenRequest',
    components: {Modal},
    props: {
      invalidToken: Boolean
    },
    data() {
      return {
        success: false,
        email: null,
        loading: false
      }
    },
    computed: {
      ...mapState('layout', ['customDomain']),
      backGroundImage() {
        return this.customDomain !== '' ? '/img/assets/'+this.customDomain+'/login-default-bg.jpg' : require('@/assets/img/login-default-bg.png')
      }
    },
    watch: {
      email() {
        this.email = this.email.toLowerCase()
      }
    },
    methods: {
      async submit() {
        if (!this.$refs.form.validate()) {
          return false
        }
        try {
          this.loading = true
          this.$emit('submit')
          await temporaryTokenRequest(this.email)
          this.success = true
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
